<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex flex-column align-items-start">
              <h4 class="card-title">
                <b>REGISTRO CAPACITACIONES</b>
              </h4>
            </div>
          </template>
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col-12 col-md-10">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
              <!-- btn new capacitacion -->
              <div class="col-12 col-md">
                <BtnAddCardTable
                  text="Nueva Capacitación"
                  url="RegistroCapacitaciones_agregar"
                />
              </div>
            </div>
          </div>
          <!-- table registroCapacitaciones -->
          <div class="px-4 mt-3">
            <table
              id="datatable_registroCapacitaciones"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  <th>Detalles</th>
                  <th>Disponibilidad</th>
                  <th>Estado Capacitación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listaItems" :key="item.id">
                  <td
                    class="text-truncate"
                    style="max-width: 200px"
                    v-b-tooltip="{
                      title: `${item.nombre}`,
                      placement: 'top',
                      variant: 'info',
                    }"
                  >
                    {{ item.nombre }}
                  </td>
                  <td
                    class="text-truncate"
                    style="max-width: 200px"
                    v-b-tooltip="{
                      title: `${item.descripcion}`,
                      placement: 'top',
                      variant: 'info',
                    }"
                  >
                    {{ item.descripcion }}
                  </td>
                  <td>{{ item.fechaInicio | formatDate }}</td>
                  <td>{{ item.fechaFin | formatDate }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <!-- <b-button
                        class="d-flex"
                        v-b-modal.detailsCap
                        @click="filterData(item.id)"
                      >
                        <i class="fas fa-eye"></i
                      ></b-button> -->
                      <vs-button
                        danger
                        icon
                        v-b-modal.detailsCap
                        @click="filterData(item.id)"
                      >
                        <i class="fas fa-eye"></i
                      ></vs-button>
                    </div>
                  </td>
                  <td>
                    <template>
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-form-checkbox
                          class="mb-3"
                          v-model="item.disponibilidad"
                          name="check-button"
                          size="lg"
                          :disabled="item.estadoCapacitacion == 1"
                          switch
                          @change="toggleDisponible(index, item)"
                        >
                        </b-form-checkbox>
                        <i
                          v-if="isConfirmDisponibilidadSingleMap[item.id]"
                          class="fas fa-spinner fa-spin-pulse"
                          style="font-size: 1.2rem"
                        ></i>
                      </div>
                    </template>
                  </td>
                  <td>
                    <h4>
                      <b-badge
                        :variant="`${
                          item.estadoCapacitacion && item.disponibilidad
                            ? 'success'
                            : !item.estadoCapacitacion && item.disponibilidad
                            ? 'warning'
                            : 'danger'
                        } `"
                      >
                        <i
                          :class="`fas ${
                            item.estadoCapacitacion && item.disponibilidad
                              ? 'fa-check'
                              : !item.estadoCapacitacion && item.disponibilidad
                              ? 'fa-clock'
                              : 'fa-ban'
                          }`"
                        ></i
                        >&nbsp;
                        {{
                          item.estadoCapacitacion && item.disponibilidad
                            ? "Finalizada"
                            : !item.estadoCapacitacion && item.disponibilidad
                            ? "Pendiente"
                            : "Cancelada"
                        }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- modal cargar archivos -->
          <b-modal
            title="Carga de archivos"
            id="fileUpload"
            size="md"
            hide-footer
          >
            <FileUploadAndPreviewPdf
              :data="listaItems"
              :item="dataFilterDetails"
              folders="users|filesContrato_capacitacion"
            />
          </b-modal>

          <!-- modal ver mas datalles -->
          <b-modal
            id="detailsCap"
            title="Detalles Capacitación"
            v-model="modalShowDetailsCap"
            size="xl"
            hide-footer
          >
            <div>
              <b-card>
                <div class="row flex-column-reverse flex-lg-row">
                  <!-- data info capacitacion -->
                  <div class="col-12 col-lg">
                    <!-- alert capacitacion cancelada -->
                    <b-alert
                      v-if="!dataFilterDetails.disponibilidad"
                      show
                      variant="danger"
                      class="overflow-hidden position-relative"
                    >
                      <!-- icon ban -->
                      <i
                        class="fas fa-ban position-absolute"
                        :style="`
                          fontSize: ${
                            dataFilterDetails.motivoCancelacion?.length > 200
                              ? '10em'
                              : '5em'
                          };
                                                right: ${
                                                  dataFilterDetails
                                                    .motivoCancelacion?.length >
                                                  200
                                                    ? '-1rem'
                                                    : '-.5rem'
                                                };
                                                bottom: ${
                                                  dataFilterDetails
                                                    .motivoCancelacion?.length >
                                                  200
                                                    ? '-1rem'
                                                    : '-.5rem'
                                                };
                          opacity: 20%;
                          `"
                      ></i>
                      <!-- motivo cancelacion -->
                      <div class="d-flex flex-column">
                        <span class="font-weight-bold text-danger"
                          >Motivo cancelación
                        </span>
                        <span
                          v-b-tooltip="{
                            title: `${dataFilterDetails.motivoCancelacion}`,
                            placement: 'bottom',
                            variant: 'danger',
                          }"
                          class="text-dark"
                          style="font-size: 1.2rem"
                          >{{
                            dataFilterDetails.motivoCancelacion?.length > 200
                              ? dataFilterDetails.motivoCancelacion?.slice(
                                  0,
                                  200
                                ) + "..."
                              : dataFilterDetails.motivoCancelacion
                          }}
                        </span>
                      </div>
                    </b-alert>

                    <div class="d-flex align-items-center">
                      <div
                        style="
                          background-color: #df122e;
                          width: 7px;
                          border-radius: 0 20px 0 20px;
                          height: 30px;
                        "
                      ></div>
                      <h4
                        v-b-tooltip="{
                          title: `${dataFilterDetails.nombre}`,
                          placement: 'bottom',
                          variant: 'info',
                        }"
                        style="
                          font-size: 1.5rem;
                          font-weight: bolder;
                          margin-left: 5px;
                          max-width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ dataFilterDetails.nombre }}
                      </h4>
                    </div>
                    <!-- access info -->
                    <div
                      class="d-flex align-items-center flex-wrap mt-3"
                      style="gap: 1.5rem"
                    >
                      <!-- duracion -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i class="far fa-clock" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50"
                            >Duración</small
                          >
                          <span style="font-weight: bold; font-size: 1.1rem"
                            >{{ dataFilterDetails.duracion }} h</span
                          >
                        </div>
                      </div>

                      <!-- divisor -->
                      <div
                        style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        "
                      ></div>

                      <!-- fecha inicio -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i
                            class="far fa-calendar-check"
                            style="font-size: 1.5rem"
                          ></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50"
                            >Fecha inicio</small
                          >
                          <span
                            style="font-size: 1.1rem"
                            class="font-weight-bold"
                          >
                            {{ calcularTextoFecha() }}</span
                          >
                        </div>
                      </div>

                      <!-- divisor -->
                      <div
                        style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        "
                      ></div>
                      <!-- costo total -->
                      <div class="d-flex align-items-center">
                        <div
                          class="d-flex justify-content-center align-items-center mr-2"
                        >
                          <i
                            class="fas fa-arrow-trend-up"
                            style="font-size: 1.5rem"
                          ></i>
                        </div>
                        <div class="d-flex flex-column text-white-50">
                          <small class="font-weight-bold">Costo total</small>
                          <span style="font-weight: bold; font-size: 1.1rem">{{
                            dataFilterDetails.costoTotal
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- descripcion -->
                    <div class="my-3">
                      <p
                        class="text-white-50"
                        style="font-size: 1rem"
                        v-b-tooltip="{
                          title: `${dataFilterDetails.descripcion}`,
                          placement: 'bottom',
                          variant: 'info',
                        }"
                      >
                        {{
                          dataFilterDetails.descripcion.length > 320
                            ? dataFilterDetails.descripcion.slice(0, 320) +
                              "..."
                            : dataFilterDetails.descripcion
                        }}
                      </p>
                    </div>
                    <!-- areas capacitacion -->
                    <div class="d-flex flex-wrap" style="gap: 0.5rem">
                      <small
                        class="rounded p-1 text-primary border"
                        v-for="area in dataFilterDetails.areasCapacitacion"
                        :key="area.id"
                        >{{ area.descripcion }}</small
                      >
                    </div>

                    <!-- ver mas detalles -->
                    <div class="mt-3">
                      <b-button
                        v-b-toggle.viewDetails
                        size="xl"
                        style="border-radius: 0 25px 0 25px"
                        block
                      >
                        Más detalles
                        <i class="fas fa-chevron-down ml-1"></i
                      ></b-button>
                      <b-collapse visible id="viewDetails">
                        <!-- mas info -->
                        <div class="mt-2 d-flex flex-wrap" style="gap: 1.5rem">
                          <!-- fecha creacion -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Fecha creación</span
                            >
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #4788ff;
                                "
                              ></div>
                              <h6 class="pl-1">
                                {{ dataFilterDetails.createdAt | timeSince }}
                              </h6>
                            </div>
                          </div>
                          <!-- empresa -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Empresa</span
                            >
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6
                                v-b-tooltip="{
                                  title: `${dataFilterDetails.empresaCapacitadora}`,
                                  placement: 'top',
                                  variant: 'info',
                                }"
                                class="pl-1"
                              >
                                {{
                                  dataFilterDetails.empresaCapacitadora.length >
                                  27
                                    ? dataFilterDetails.empresaCapacitadora.slice(
                                        0,
                                        27
                                      ) + "..."
                                    : dataFilterDetails.empresaCapacitadora
                                }}
                              </h6>
                            </div>
                          </div>
                          <!-- documento -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Documento</span
                            >
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6
                                class="pl-1"
                                v-b-tooltip="{
                                  title: `${dataFilterDetails.tipoDocumento?.descripcion} ${dataFilterDetails.numeroDocumentoEmpresa}`,
                                  placement: 'top',
                                  variant: 'info',
                                }"
                              >
                                {{
                                  formatTipoDocumento(
                                    dataFilterDetails.tipoDocumento?.descripcion
                                  )
                                }}
                                {{ dataFilterDetails.numeroDocumentoEmpresa }}
                              </h6>
                            </div>
                          </div>
                          <!-- tipo empresa-->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Tipo empresa
                            </span>
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6 class="pl-1">
                                {{
                                  dataFilterDetails.tipoEmpresaCapacitacion
                                    ?.descripcion
                                }}
                              </h6>
                            </div>
                          </div>
                          <!-- contrato -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Contrato
                            </span>
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6
                                v-b-tooltip="{
                                  title: `${dataFilterDetails.numeroContrato}`,
                                  placement: 'top',
                                  variant: 'info',
                                }"
                                class="pl-1"
                              >
                                {{
                                  dataFilterDetails.numeroContrato.length > 27
                                    ? dataFilterDetails.numeroContrato.slice(
                                        0,
                                        27
                                      ) + "..."
                                    : dataFilterDetails.numeroContrato
                                }}
                              </h6>
                            </div>
                          </div>
                          <!--fecha contrato -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Fecha contrato
                            </span>
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6 class="pl-1">
                                {{
                                  dataFilterDetails.fechaContrato | formatDate
                                }}
                              </h6>
                            </div>
                          </div>
                          <!--instructor -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Instructor
                            </span>
                            <div class="d-flex align-items-center">
                              <div
                                style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "
                              ></div>
                              <h6
                                v-b-tooltip="{
                                  title: `${dataFilterDetails.nombreInstructor}`,
                                  placement: 'top',
                                  variant: 'info',
                                }"
                                class="pl-1"
                                style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                "
                              >
                                {{
                                  dataFilterDetails.nombreInstructor.length > 27
                                    ? dataFilterDetails.nombreInstructor.slice(
                                        0,
                                        27
                                      ) + "..."
                                    : dataFilterDetails.nombreInstructor
                                }}
                              </h6>
                            </div>
                          </div>
                          <!--certificacion -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Certificado finalización
                            </span>
                            <div class="d-flex align-items-center">
                              <div
                                :style="`
                                  width: 7px;
                                  height: 7px;
                                  borderRadius: 50%;
                                  backgroundColor: ${
                                    dataFilterDetails.certificacion
                                      ? '#16A275'
                                      : '#df122e'
                                  }  ;`"
                              ></div>
                              <h6 class="pl-1">
                                {{
                                  dataFilterDetails.certificacion
                                    ? "Si, lo incluye"
                                    : "No incluye"
                                }}
                              </h6>
                            </div>
                          </div>
                          <!--uploadFiles -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Archivos
                            </span>
                            <div class="d-flex align-items-center">
                              <i
                                :class="`fas  ${
                                  dataFilterDetails.estadoUploadFiles
                                    ? 'fa-circle-check text-success'
                                    : 'fa-warning text-warning'
                                }  `"
                              ></i>
                              <h6 class="pl-1">
                                {{
                                  dataFilterDetails.estadoUploadFiles
                                    ? "Subidos"
                                    : "Pendientes"
                                }}
                              </h6>

                              <i
                                v-if="dataFilterDetails.disponibilidad"
                                class="far fa-folder-open mx-2 zoom"
                                v-b-modal.fileUpload
                                style="font-size: 1rem"
                              ></i>
                            </div>
                          </div>
                          <!-- list asistentes -->
                          <div class="d-flex align-items-center">
                            <b-button
                              variant="outline-info"
                              v-b-modal.detailsListAsistentes
                            >
                              <i class="fas fa-user-gear"></i>
                              Listado de asistentes
                            </b-button>
                          </div>
                          <!--finalizar capacitacion -->
                          <div>
                            <span
                              style="
                                padding-left: 5px;
                                opacity: 70%;
                                font-weight: bold;
                                font-size: 0.7rem;
                              "
                              >Finalizar capacitación
                            </span>
                            <div class="d-flex align-items-center">
                              <b-form-checkbox
                                v-model="dataFilterDetails.estadoCapacitacion"
                                name="check-button"
                                size="lg"
                                :disabled="!dataFilterDetails.disponibilidad"
                                switch
                                @change="
                                  toggleFinalizarCapacitacion(dataFilterDetails)
                                "
                              >
                              </b-form-checkbox>
                              <i
                                v-if="
                                  isConfirmFinishCapMap[dataFilterDetails.id]
                                "
                                class="fas fa-spinner fa-spin-pulse"
                                style="font-size: 1.2rem; margin-bottom: -13px"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>

                    <!-- modal lista asistentes -->
                    <b-modal
                      id="detailsListAsistentes"
                      title="Listado de asistentes"
                      size="xl"
                      hide-footer
                      @shown="initializeDataTable"
                    >
                      <table
                        id="datatable_dataAsistente"
                        class="table table-bordered table-responsive-lg table-striped text-center animated-table hidden"
                      >
                        <thead>
                          <tr>
                            <th style="width: 100px">Usuario</th>
                            <th style="width: 100px">Calificación</th>
                            <th style="width: 200px">Comentario</th>
                            <th style="width: 100px">
                              Confirmación asistencia
                            </th>
                            <th style="width: 100px">
                              Asistencia real
                              <i
                                v-if="
                                  !dataFilterDetails.dataListAsistents?.every(
                                    (item) => item.asistenciaReal
                                  )
                                "
                                v-b-tooltip.hover="{ variant: 'info' }"
                                title="Confirmar todas"
                                :class="`fas ${
                                  isConfirmAsistenciaReal
                                    ? 'fa-spinner fa-spin-pulse'
                                    : 'fa-check zoom '
                                } px-2`"
                                style="font-size: 1.2rem"
                                @click="
                                  toggleConfirmAll(
                                    dataFilterDetails.dataListAsistents
                                  )
                                "
                              ></i>
                            </th>
                            <th
                              v-if="dataFilterDetails.certificacion"
                              style="width: 100px"
                            >
                              Certificado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in dataFilterDetails?.dataListAsistents"
                            :key="item?.id"
                          >
                            <td
                              v-b-tooltip.hover="{ variant: 'info' }"
                              :title="`${item.asistente.nombres} ${item.asistente.apellidos}`"
                              class="text-truncate"
                            >
                              {{
                                `${item.asistente.nombres} ${item.asistente.apellidos}`
                              }}
                            </td>
                            <td class="text-truncate">
                              <b-form-rating
                                id="rating-inline"
                                variant="warning"
                                inline
                                no-border
                                readonly
                                :value="item.calificacionCapacitacion"
                                style="background-color: transparent"
                              ></b-form-rating>
                            </td>
                            <td
                              v-b-tooltip.hover="{ variant: 'info' }"
                              :title="`${item.comentarioAsistente}`"
                              class="text-truncate"
                            >
                              {{ item.comentarioAsistente }}
                            </td>
                            <td>
                              <b-badge
                                style="width: 100px"
                                :variant="
                                  item.confirmacionAsistente !== 0
                                    ? 'success'
                                    : 'warning'
                                "
                              >
                                {{
                                  item.confirmacionAsistente !== 0
                                    ? "Confirmado"
                                    : "Pendiente"
                                }}

                                <i
                                  :class="`fas fa-${
                                    item.confirmacionAsistente !== 0
                                      ? 'check'
                                      : 'clock'
                                  } ml-1`"
                                ></i>
                              </b-badge>
                            </td>
                            <td>
                              <template>
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <b-form-checkbox
                                    class="mb-3"
                                    v-model="item.asistenciaReal"
                                    name="check-button"
                                    size="lg"
                                    switch
                                    @change="toggleAsistenciaReal(item)"
                                  >
                                  </b-form-checkbox>
                                  <i
                                    v-if="
                                      isConfirmAsistenciaRealSingleMap[item.id]
                                    "
                                    class="fas fa-spinner fa-spin-pulse"
                                    style="font-size: 1.2rem"
                                  ></i>
                                </div>
                              </template>
                            </td>
                            <td v-if="dataFilterDetails.certificacion">
                              <b-badge
                                style="width: 100px"
                                :variant="
                                  item.estadoUploadCertificado !== 0
                                    ? 'primary'
                                    : 'light'
                                "
                              >
                                {{
                                  item.estadoUploadCertificado !== 0
                                    ? "Subido"
                                    : "Pendiente"
                                }}
                                <i class="fas fa-cloud-arrow-up ml-1"></i>
                              </b-badge>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-modal>
                  </div>
                  <!-- grafic progress confirmacion asistentes  -->
                  <div class="col-12 col-lg">
                    <b-card class="bg-card-r bg-dark">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center"
                        style="height: 100%"
                      >
                        <!-- component ProgressChart -->
                        <div
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <ProgressChart
                            titleCheck="Confirmadas"
                            :total="dataFilterDetails.listaAsistentesId.length"
                            :confirmed="
                              dataFilterDetails.confirmAsistent?.length
                            "
                          />
                          <h3
                            class="font-weight-bold mt-3 mb-1 text-uppercase text-center"
                          >
                            Asistencias Confirmadas
                          </h3>
                          <span
                            v-b-tooltip="{
                              title: `Modalidad`,
                              placement: 'top',
                              variant: 'danger',
                            }"
                            class="font-weight-bold text-white-50"
                            style="font-size: 1rem"
                          >
                            {{
                              dataFilterDetails.modalidadCapacitacion
                                ?.descripcion
                            }}</span
                          >
                        </div>
                        <!-- info icons -->
                        <div
                          class="d-flex justify-content-center mt-4"
                          style="gap: 2rem"
                        >
                          <!-- calificacion -->
                          <div
                            class="d-flex flex-column align-items-center"
                            style="gap: 0.7rem; width: 48px"
                          >
                            <div
                              v-b-tooltip="{
                                title: `Promedio calificaciones - ${dataFilterDetails.promedioCalificaciones?.data.length} respuestas`,
                                placement: 'top',
                                variant: 'info',
                              }"
                              class="d-flex justify-content-center rounded align-items-center bg-warning"
                              style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #fe721c;
                              "
                            >
                              <i
                                class="fas fa-star-half-stroke fa-fade"
                                style="font-size: 1.2rem"
                              ></i>
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span style="font-size: 1.8rem; font-weight: bold"
                                >{{
                                  dataFilterDetails.promedioCalificaciones
                                    ?.promedio
                                }}
                              </span>
                              <em
                                class="text-white-50 text-nowrap"
                                style="font-size: 0.8rem"
                                >valoración</em
                              >
                            </div>
                          </div>

                          <!-- divisor -->
                          <div
                            style="width: 1px; background-color: #102e5f"
                          ></div>
                          <!-- comentarios -->
                          <div
                            class="d-flex flex-column align-items-center"
                            style="gap: 0.7rem; width: 48px"
                          >
                            <div
                              v-b-tooltip="{
                                title: `Comentarios`,
                                placement: 'top',
                                variant: 'info',
                              }"
                              class="d-flex justify-content-center rounded align-items-center bg-info"
                              style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #4788ff;
                              "
                            >
                              <i
                                class="fas fa-comments fa-fade"
                                style="font-size: 1.2rem"
                              ></i>
                            </div>
                            <div
                              class="d-flex flex-column justify-content-center align-items-center"
                            >
                              <span style="font-size: 1.8rem; font-weight: bold"
                                >{{
                                  dataFilterDetails.commentsAsistents?.length
                                }}
                              </span>
                              <em
                                class="text-white-50"
                                style="font-size: 0.8rem"
                                >comentarios</em
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </b-card>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import ProgressChart from "@/components/charts/ProgressChart.vue";
import FileUploadAndPreviewPdf from "./FileUploadAndPreviewPdf.vue";
const Swal = require("sweetalert2");
export default {
  name: "RegistroCapacitaciones",
  components: {
    CardTable,
    BtnAddCardTable,
    ProgressChart,
    FileUploadAndPreviewPdf,
  },
  data() {
    return {
      persona: {},
      dataTableInstance: null,
      isHovered: false,
      isConfirmAsistenciaReal: null,
      isConfirmFinishCapMap: {},
      isConfirmAsistenciaRealSingleMap: {},
      isConfirmDisponibilidadSingleMap: {},
      ListaAreasCapacitacion: [],
      listPlanCapacitacionProgreso: [],
      listaItems: [],
      modalShowDetailsCap: false,
      dataFilterDetails: {
        id: "",
        nombre: "",
        descripcion: "",
        areasCapacitacionId: [],
        fechaInicio: "",
        fechaFin: "",
        duracion: "",
        nombreInstructor: "",
        empresaCapacitadora: "",
        tipoEmpresaId: "",
        numeroDocumentoEmpresa: "",
        tipoDocumentoEmpresaId: "",
        numeroContrato: "",
        fechaContrato: "",
        modalidadCapacitacionId: "",
        costoTotal: "",
        listaAsistentesId: [],
        disponibilidad: 1,
        motivoCancelacion: "",
        estadoCapacitacion: 0,
        createdAt: "",
        responsableId: "",
        estadoUploadFiles: 0,
        areasCapacitacion: [],
        certificacion: null,
      },
      dataCards: [
        {
          title: "Finalizadas",
          value: () =>
            this.listaItems.filter(
              (cap) => cap.estadoCapacitacion && cap.disponibilidad
            ).length,
          iconName: "calendar-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Finalizada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Pendientes",
          value: () =>
            this.listaItems.filter(
              (cap) => !cap.estadoCapacitacion && cap.disponibilidad
            ).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Pendiente").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Canceladas",
          value: () =>
            this.listaItems.filter((cap) => !cap.disponibilidad).length,
          iconName: "ban",
          color: "rgba(223, 18, 46, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Cancelada").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Total asistentes",
          value: () =>
            this.listaItems.reduce(
              (totalAsistentes, cap) =>
                totalAsistentes + cap.listaAsistentesId.split(",").length,
              0
            ),
          iconName: "users",
          color: "rgba(71, 136, 255, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => console.log("Activated"),
          onDeactivate: () => console.log("Deactivate"),
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getListCapacitacionProgresoAll();
    await this.getAreaCapacitacion();
    core.index();

    if (
      window.$.fn.DataTable.isDataTable("#datatable_registroCapacitaciones")
    ) {
      window.$("#datatable_registroCapacitaciones").DataTable().destroy();
    }
    this.dataTableInstance = window
      .$("#datatable_registroCapacitaciones")
      .DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    this.$isLoading(false);
  },
  methods: {
    initializeDataTable() {
      if (window.$.fn.DataTable.isDataTable("#datatable_dataAsistente")) {
        window.$("#datatable_dataAsistente").DataTable().destroy();
      }

      window.$("#datatable_dataAsistente").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      window.$("#datatable_dataAsistente").removeClass("hidden");
    },

    async getListCapacitacionProgresoAll() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PlanCapacitacionProgreso/ListAll/",
        });
        console.log("list planCapacitacionProgreso...", res);
        if (res.length > 0) {
          this.listPlanCapacitacionProgreso = res;
        } else {
          this.listPlanCapacitacionProgreso = [];
        }
      } catch (error) {
        this.listPlanCapacitacionProgreso = [];
        console.log("err", error);
      }
    },
    // Método para cambiar el estado de 'capacitacion'
    async toggleAsistenciaReal(item) {
      try {
        this.$set(this.isConfirmAsistenciaRealSingleMap, item.id, true); // Establece el spinner en true para el elemento actual
        let res = await this.$store.dispatch("hl_get", {
          path: "PlanCapacitacionProgreso/ToogleAsistenciaReal/" + item.id,
        });
        if (res) {
          this.$set(this.isConfirmAsistenciaRealSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
          Swal.fire(
            "Listo!",
            "Se ha cambiado el estado correctamente.",
            "success"
          );
        }
      } catch (error) {
        console.log(error);
        this.$set(this.isConfirmAsistenciaRealSingleMap, item.id, false); // Establece el spinner en false en caso de error
        Swal.fire("Error!", "Ha ocurrido un error.", "error");
        return null;
      }
    },
    // Método para cambiar el estado de 'asistencia real'
    async toggleFinalizarCapacitacion(item) {
      try {
        this.$set(this.isConfirmFinishCapMap, item.id, true); // Establece el spinner en true para el elemento actual
        let res = await this.$store.dispatch("hl_get", {
          path:
            "PlanRegistroCapacitaciones/TogglePlanCapacitacionEstado/" +
            item.id,
        });
        if (res.success) {
          this.$set(this.isConfirmFinishCapMap, item.id, false); // Establece el spinner en false después de la operación exitosa
          this.listaItems.find((cap) => cap.id == item.id).estadoCapacitacion =
            !this.listaItems.find((cap) => cap.id == item.id)
              .estadoCapacitacion;
          console.log(this.listaItems.find((cap) => cap.id == item.id));
          Swal.fire(
            "Listo!",
            "Se ha cambiado el estado correctamente.",
            "success"
          );
        }
      } catch (error) {
        console.log(error);
        this.$set(this.isConfirmFinishCapMap, item.id, false); // Establece el spinner en false en caso de error
        Swal.fire("Error!", "Ha ocurrido un error.", "error");
        return null;
      }
    },
    // Método para cambiar el estado de 'asistencia real a todos'
    async toggleConfirmAll(data) {
      try {
        this.isConfirmAsistenciaReal = true;
        const promises = data
          .filter((item) => item.asistenciaReal === false) // Filtra solo los elementos con asistenciaReal en false
          .map(async (item) => {
            // Realiza la llamada asincrónica y espera su resolución
            return this.$store.dispatch("hl_get", {
              path: "PlanCapacitacionProgreso/ToogleAsistenciaReal/" + item.id,
            });
          });

        // Espera a que todas las llamadas asincrónicas se completen
        const results = await Promise.all(promises);

        // Verifica si todas las llamadas fueron exitosas
        const allSuccessful = results.every((result) => result === true);

        if (allSuccessful) {
          this.isConfirmAsistenciaReal = false;
          data = data.map((item) => {
            item.asistenciaReal = true;
            return item;
          });
          console.log("newwwwww", data);
          Swal.fire(
            "Listo!",
            "Se ha confirmado la asistencia real para todas.",
            "success"
          );
        } else {
          this.isConfirmAsistenciaReal = false;
          Swal.fire("Error!", "Ha ocurrido un error.", "error");
        }
      } catch (error) {
        console.log(error);
        this.isConfirmAsistenciaReal = false;
        Swal.fire("Error!", "Ha ocurrido un error.", "error");
        return null;
      }
    },

    // Método para cambiar el estado de 'disponibilidad'
    async toggleDisponible(rowIndex, item) {
      console.log(item.disponibilidad);
      if (!item.disponibilidad) {
        const result = await Swal.fire({
          input: "textarea",
          inputLabel: "Motivo cancelación",
          inputPlaceholder: "Escriba un comentario...",
          inputAttributes: {
            "aria-label": "Escriba un comentario",
            style: "color:#fff",
          },
          showCancelButton: true,
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
          this.$set(this.isConfirmDisponibilidadSingleMap, item.id, true); // Establece el spinner en true para el elemento actual
          const text = result.value;
          if (text) {
            let res = await this.$store.dispatch("hl_post", {
              path: "PlanRegistroCapacitaciones/ToggleDisponibilidad/",
              data: {
                planId: item.id,
                motivoCancelacion: text,
              },
            });
            if (res.success) {
              this.$set(this.isConfirmDisponibilidadSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
              // Redibuja solo la fila que cambió
              this.dataTableInstance.row(rowIndex).invalidate().draw();
              Swal.fire(
                "Listo!",
                "Se ha cancelado la capacitación correctamente.",
                "success"
              );
              item.motivoCancelacion = text;
            } else {
              this.$set(this.isConfirmDisponibilidadSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
              item.disponibilidad = true;
            }
          } else {
            this.$set(this.isConfirmDisponibilidadSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
            Swal.fire(
              "Recomendación!",
              "Por favor agrege un motivo.",
              "warning"
            );
            item.disponibilidad = true;
          }
        } else {
          // El usuario hizo clic en "Cancelar"
          item.disponibilidad = true;
        }
      } else {
        this.$set(this.isConfirmDisponibilidadSingleMap, item.id, true); // Establece el spinner en true para el elemento actual
        let res = await this.$store.dispatch("hl_post", {
          path: "PlanRegistroCapacitaciones/ToggleDisponibilidad/",
          data: {
            planId: item.id,
            motivoCancelacion: "",
          },
        });
        if (res.success) {
          this.$set(this.isConfirmDisponibilidadSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
          // Redibuja solo la fila que cambió
          this.dataTableInstance.row(rowIndex).invalidate().draw();
          Swal.fire(
            "Listo!",
            "Se ha activado nuevamente esta capacitación.",
            "success"
          );
        } else {
          this.$set(this.isConfirmDisponibilidadSingleMap, item.id, false); // Establece el spinner en false después de la operación exitosa
          item.disponibilidad = false;
        }
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel == 4
              ? "PlanRegistroCapacitaciones/"
              : "PlanRegistroCapacitaciones/ByResponsableId/" + this.persona.id
          }`,
        });
        console.log("list PlanRegistroCapcitaciones...", res);
        if (res.length > 0) {
          this.listaItems = res.map((cap) => {
            cap.disponibilidad = cap.disponibilidad == 1 ? true : false;
            cap.estadoCapacitacion = cap.estadoCapacitacion == 1 ? true : false;
            cap.certificacion = cap.certificacion == 1 ? true : false;
            return cap;
          });
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },

    async getAreaCapacitacion() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "AreaCapacitacion/ListAreaCapacitacion",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.ListaAreasCapacitacion = res;
          ////console.log(res)
        } else {
          this.ListaAreasCapacitacion = [];
        }
      } catch (error) {
        this.ListaAreasCapacitacion = [];
        console.log("err", error);
      }
    },
    filterData(id) {
      this.modalShowDetailsCap = !this.modalShowDetailsCap;

      console.log(this.modalShowDetailsCap);

      const filteredData = this.listaItems.filter((cap) => cap.id === id);

      const dataListAsistents = this.listPlanCapacitacionProgreso
        .filter((item) => item.capacitacionId === id)
        .map((item) => {
          item.asistenciaReal = item.asistenciaReal == 1 ? true : false;
          return item;
        });

      console.log("dataListAsistessssssssssss--->", dataListAsistents);

      const confirmAsistentIds = this.listPlanCapacitacionProgreso
        .filter(
          (item) =>
            item.capacitacionId === id && item.confirmacionAsistente == 1
        )
        .map((item) => item.asistenteId);

      const commentsAsistents = this.listPlanCapacitacionProgreso
        .filter(
          (item) =>
            item.capacitacionId === id && item.comentarioAsistente !== ""
        )
        .map((item) => item);

      const calificationsAsistents = this.listPlanCapacitacionProgreso
        .filter(
          (item) =>
            item.capacitacionId === id && item.calificacionCapacitacion !== 0
        )
        .map((item) => item);

      // Calcula la suma de todas las calificaciones
      const sumaCalificaciones = calificationsAsistents.reduce(
        (total, data) => total + data.calificacionCapacitacion,
        0
      );

      // Calcula el promedio dividiendo la suma por la cantidad de calificaciones y redondea a dos decimales
      const promedioCalificaciones = (
        sumaCalificaciones / calificationsAsistents.length
      ).toFixed(1);

      // Convierte el resultado en un número (ya que toFixed devuelve una cadena)
      const promedioRedondeado = parseFloat(promedioCalificaciones);

      console.log("confirmacio asistente", confirmAsistentIds);
      console.log("comentarios asistentes", commentsAsistents);
      console.log(
        "calificaciones asistentes",
        calificationsAsistents,
        isNaN(promedioRedondeado) ? 0 : promedioRedondeado
      );

      if (filteredData.length > 0) {
        const firstMatchingObject = filteredData[0];
        this.dataFilterDetails = {
          ...firstMatchingObject,
          listaAsistentesId: firstMatchingObject.listaAsistentesId.split(","),
          areasCapacitacionId:
            firstMatchingObject.areasCapacitacionId.split(","),
          areasCapacitacion: this.ListaAreasCapacitacion.filter((area) =>
            firstMatchingObject.areasCapacitacionId.includes(area.id)
          ),
          confirmAsistent: confirmAsistentIds,
          commentsAsistents: commentsAsistents,
          promedioCalificaciones: {
            data: calificationsAsistents,
            promedio: isNaN(promedioRedondeado) ? 0 : promedioRedondeado,
          },
          dataListAsistents: dataListAsistents,
        };
        console.log(this.dataFilterDetails);
      } else {
        this.dataFilterDetails = { ...this.dataFilterDetails };
      }
    },
    formatTipoDocumento(tipoDocumento) {
      switch (tipoDocumento) {
        case "Cedula de ciudadania":
          return "CC";
        case "Tarjeta de identidad":
          return "TI";
        case "Registro Civil":
          return "RC";
        case "Pasaporte":
          return "PA";
        case "Cedula de extranjeria":
          return "CE";
        case "Tarjeta de extranjeria":
          return "CE";
        case "NIT de otro pais":
          return "NIT/OP";
        case "Documento de identificacion extranjero":
          return "DIE";
        case "No Aplica":
          return "N/A";
        default:
          return tipoDocumento;
      }
    },
    calcularTextoFecha() {
      const diferenciaDias = this.diferenciaDias;

      if (diferenciaDias > 0) {
        if (diferenciaDias === 1) {
          return "Falta 1 día";
        } else if (diferenciaDias < 7) {
          return `Faltan ${diferenciaDias} días`;
        } else if (diferenciaDias < 30) {
          const semanas = Math.floor(diferenciaDias / 7);
          return `Faltan ${semanas} semana${semanas === 1 ? "" : "s"}`;
        } else if (diferenciaDias < 365) {
          const meses = Math.floor(diferenciaDias / 30);
          return `Faltan ${meses} mes${meses === 1 ? "" : "es"}`;
        } else {
          const anios = Math.floor(diferenciaDias / 365);
          return `Faltan ${anios} año${anios === 1 ? "" : "s"}`;
        }
      } else if (diferenciaDias < 0) {
        const absDias = Math.abs(diferenciaDias);
        if (absDias === 1) {
          return "Hace 1 día";
        } else if (absDias < 7) {
          return `Hace ${absDias} días`;
        } else if (absDias < 30) {
          const semanas = Math.floor(absDias / 7);
          return `Hace ${semanas} semana${semanas === 1 ? "" : "s"}`;
        } else if (absDias < 365) {
          const meses = Math.floor(absDias / 30);
          return `Hace ${meses} mes${meses === 1 ? "" : "es"}`;
        } else {
          const anios = Math.floor(absDias / 365);
          return `Hace ${anios} año${anios === 1 ? "" : "s"}`;
        }
      } else {
        return "Hoy";
      }
    },
  },
  computed: {
    diferenciaDias() {
      const fechaInicio = moment_timezone(this.dataFilterDetails.fechaInicio);
      const fechaActual = moment_timezone();

      const diferencia = fechaInicio.diff(fechaActual, "days");

      return diferencia;
    },
  },
  filters: {
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("es-ES");
      return formattedDate;
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },
};
</script>

<style scoped>
/* .bg-card-r{
  height: 100%;
  background-image: url('/img/blob-scene-haikei-l.svg');
  background-size: cover;
  background-position: center;
} */
/* .dark .bg-card-r{
  height: 100%;
  background-image: url('/img/blob-scene-haikei.svg');
  background-size: cover;
  background-position: center; 
} */
.animated-table {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
}

.animated-table.hidden {
  opacity: 0;
  visibility: hidden;
}
</style>
